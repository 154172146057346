import {Component, OnInit} from '@angular/core';
import {SeonService} from '../../casino/services/seon.service';
import {ScriptService} from '../../casino/services/script.service';
import { v4 as uuidv4 } from 'uuid';
import {environment} from '../../../environments/environment';

const SCRIPT_PATH = 'https://cdn.seondf.com/js/v5/agent.js';
declare let seon: any;

@Component({
  selector: 'app-seon-agent',
  templateUrl: './seon-agent.component.html',
  styleUrls: ['./seon-agent.component.scss']
})
export class SeonAgentComponent  implements OnInit {

  constructor(private seonService: SeonService,
              private scriptService: ScriptService) {
  }

  ngOnInit(): void {
    if(environment.production && environment.seon.enabled) {
      this.loadSeonScript();
    }
  }

  loadSeonScript(){
    const scriptElement = this.scriptService.loadJsScript(SCRIPT_PATH);
    scriptElement.onload = () => {
      this.initConfig();
    }
    scriptElement.onerror = () => {
      console.log('Could not load the Seon API Script!');
    }
  }

  initConfig() {
    const session_id = uuidv4();
    seon.config({
      host: "seondf.com",
      session_id: session_id,
      audio_fingerprint: true,
      canvas_fingerprint: true,
      webgl_fingerprint: true,
      silent_mode: false,
      max_proxy_delay: 1000,
      onSuccess: function(message) {
        console.log("success", message);
        this.getDeviceSessionPayload();
      }.bind(this),
      onError: function(message) {
        console.log("error", message);
      }
    });
  }

  getDeviceSessionPayload() {
    seon.getBase64Session(function(data) {
      if (data) {
        // console.log("Session payload", data);
        this.seonService.sessionValue = data;
      } else {
        // console.log("Failed to retrieve session data.");
        this.seonService.sessionValue = null;
      }
    }.bind(this));
  }

}
