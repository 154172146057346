import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs/index';
import {User} from '../models/user';
import {catchError, map, switchMap} from 'rxjs/operators';
import {JsonConvert} from 'json2typescript';
import {of} from 'rxjs';
import SiteConstant from '../helpers/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private jsonConvert: JsonConvert = new JsonConvert();

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(null);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public isAuthenticated(): boolean {
    if (!this.currentUserValue) {
      return false;
    }
    return true;
  }

  login(params: any) {
    return this.http.post<any>('/api/user/auth', params)
      .pipe(map(user => {
          if (user.status === 'success') {
            this.info();
          }
          return user;
        })
      );
  }

  info(skip?: boolean) {
    /* skip - Skip redirect to login. Use only once to show user info after refresh page.  */
    const params: any = {};
    if (skip) {
      params.skip = skip;
    }
    return this.http.get<any>('/api/user/info', {params}).pipe(
      map(user => {
        if (user.status === 'success') {
          this.currentUserSubject.next(this.jsonConvert.deserializeObject(user.data, User));
          return this.currentUser;
        }
      })
    );

  }

  updateUserInfo(data: any) {
    return this.http.put<any>('/api/user/info', data).pipe(map(user => {
        if (user.status === 'success') {
          const userObj: User = this.jsonConvert.deserializeObject(user.data, User);
          this.currentUserSubject.next(userObj);
          return this.currentUser;
        }
        return null;
      })
    );
  }

  logout() {
    return this.http.get<any>('/api/user/logout').pipe(map(data => {
        this.currentUserSubject.next(null);
      })
    );
  }

  googleLogin() {
    return this.http.get<any>('/api/oauth2/authorization_url/google').pipe(map(response => {
        if (response.status === 'success') {
          return response.data;
        }
        return null;
      })
    );
  }

  facebookLogin() {
    return this.http.get<any>('/api/oauth2/authorization_url/facebook').pipe(map(response => {
        if (response.status === 'success') {
          return response.data;
        }
        return null;
      })
    );
  }

  register(params, queryParams?: any) {
    return this.http.post<any>('/api/user/register', params, {params: queryParams})
      .pipe(map(response => {
          if (response.status === 'success') {
            return response.data;
          }
          return null;
        })
      );
  }

  applyCurrency(params) {
    return this.http.post<any>('/api/user/balance', params)
      .pipe(map(response => {
          if (response.status === 'success') {
            return response.data;
          }
          return null;
        })
      );
  }

  isLoggedIn(): Observable<Object> {
    return this.http.get('/api/user/info', {}).pipe(
      switchMap((res: any) => {
        if (res.status === 'success') {
          const user = this.jsonConvert.deserializeObject(res.data, User)
          if (user) {
            this.currentUserSubject.next(user);
            return of(true);
          } else {
            return of(false);
          }
        } else {
          return of(false);
        }
      }),
      catchError(error => {
        return of(false);
      })
    );
  }

  isCashierLoggedIn(): Observable<Object> {
    return this.http.get('/api/user/info', {}).pipe(
      switchMap((res: any) => {
        if (res.status === 'success') {
          const user = this.jsonConvert.deserializeObject(res.data, User)
          if (this.hasACashierRole(user)) {
            this.currentUserSubject.next(user);
            return of(true);
          } else {
            return of(false);
          }
        } else {
          return of(false);
        }
      }),
      catchError(error => {
        return of(false);
      })
    );
  }

  isAffiliateLoggedIn(): Observable<Object> {
    return this.http.get('/api/user/info', {}).pipe(
      switchMap((res: any) => {
        if (res.status === 'success') {
          const user = this.jsonConvert.deserializeObject(res.data, User)
          if (this.hasAffiliateRole(user)) {
            this.currentUserSubject.next(user);
            return of(true);
          } else {
            return of(false);
          }
        } else {
          return of(false);
        }
      }),
      catchError(error => {
        return of(false);
      })
    );
  }

  public hasAffiliateRole(user): boolean {
    if (user && user.roles) {
      return (user.roles).indexOf(SiteConstant.USER_ROLES.ROLE_AFFILIATE) !== -1;
    }
    return false;
  }


  public hasACashierRole(user): boolean {
    if (user && user.roles) {
      return (user.roles).indexOf(SiteConstant.USER_ROLES.ROLE_CASHIER) !== -1;
    }
    return false;
  }

}
