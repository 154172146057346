// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'Lions Casino',
  skin: 'lions-casino',
  domain: 'https://lions.casino',
  emails: {
    affiliates: 'affiliates@lions.casino',
    support: 'support@lions.casino',
    dataprotection: 'dataprotection@lions.casino',
    dataprivacy: 'dataprivacy@lions.casino'
  },
  sentry: {
    dns: 'https://abc0ea7da27d4d3eaef1c215492963bc@o1202490.ingest.us.sentry.io/6327766',
    replay: false
  },
  seon: {
    enabled: false
  },
  messenger: {
    freshChat: {
      enabled: false,
      host: 'https://lions-718423825425550385-9f85aa9dc1bb1e317175925.freshchat.com',
      token: '0c95dd59-584a-4bd0-81b4-c66de7ee2220'
    },
    fbChat: {
      enabled: true,
      url: 'http://m.me/333338946533189'
    }
  },
  hj: '5015609',
  ga: 'G-G1N2J9DEVS',
  language: {
    excluded: ['uk', 'pl'],
    default: 'en'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
