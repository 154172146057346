import {CommonModule} from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ToMoneyPipe,
  RelativeTimePipe,
  SafeUrlPipe,
  ToMoneyLocalPipe,
  ToPercentPipe,
  ToIntPercentPipe,
  HistoryStatusPipe,
  TimestampToDatePipe,
  AccountFormatPipe,
  DecimalToPercentPipe
} from './casino/helpers/pipes';
import {SeonAgentComponent} from './directives/seon-agent/seon-agent.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    SafeUrlPipe,
    RelativeTimePipe,
    ToMoneyPipe,
    ToMoneyLocalPipe,
    ToPercentPipe,
    ToIntPercentPipe,
    DecimalToPercentPipe,
    HistoryStatusPipe,
    TimestampToDatePipe,
    SeonAgentComponent
  ],
  exports: [
    SafeUrlPipe,
    RelativeTimePipe,
    ToMoneyPipe,
    ToMoneyLocalPipe,
    ToPercentPipe,
    ToIntPercentPipe,
    HistoryStatusPipe,
    TimestampToDatePipe,
    SeonAgentComponent,
    DecimalToPercentPipe
  ]
})
export class AppSharingModule {}
