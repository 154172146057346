import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {map} from 'rxjs/operators';
import Utils from '../helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class UtmQueryParamsService {

  private _utmLocalStorageKey = 'utmQueryParams';
  private _allowedUtmParams = ['clickid', 'pid', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

  constructor(private route: ActivatedRoute) {
    this.route.queryParams.pipe(
      map((values, index) => {
        let onlyUtmParams = {};
        if (values) {
          for (const [key, value] of Object.entries(values)) {
            if (this._allowedUtmParams.includes(key)) {
              onlyUtmParams[key] = value;
            }
          }
        }
        return onlyUtmParams;
      }),
    ).subscribe((params: any) => {
      this.setUtmQueryParams(params);
    });
  }

  setUtmQueryParams(params: any) {
    if (params && Object.keys(params).length > 0) {
      Utils.setLocalStorageItem(this._utmLocalStorageKey, JSON.stringify(params))
    }
  }

  getUtmQueryParams() {
    try {
      const params = Utils.getLocalStorageItem(this._utmLocalStorageKey);
      return params ? JSON.parse(params) : null;
    } catch (e) {
      return null;
    }
  }

  removeUtmQueryParams() {
    Utils.removeLocalStorageItem(this._utmLocalStorageKey);
  }

}
