import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(public router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status >= 500) {
        console.error('Server Errors');
        console.error(err.error);
        this.router.navigate(['internal-server-error']);
        return [];
      }
      if (err.status === 401) {
        if (request.url === '/api/user/info' && request.params.get('skip')) {
          return [];
        }
      }
      if (err.status === 401) {
        if (request.url === '/api/user/info' && !request.params.get('skip')) {
          this.router.navigate(['login'], {state: {redirectUrl: window.location.pathname}});
          return [];
        }
      }
      if (err.status === 403) {
        this.router.navigate(['forbidden']);
        return [];
      }
      return throwError(err.error);
    }));
  }
}
