import {Any, JsonObject, JsonProperty} from 'json2typescript';
import {Balance} from './balance';
import {RolePermission} from './role-permission';

@JsonObject('User')
export class User {
  @JsonProperty('id', Number, true)
  id: number = undefined;
  @JsonProperty('first_name', String, true)
  firstName: string = undefined;
  @JsonProperty('last_name', String, true)
  lastName: string = undefined;
  @JsonProperty('email', String, true)
  email: string = undefined;
  @JsonProperty('birthday', String, true)
  birthday: string = undefined;
  @JsonProperty('balances', [Balance], true)
  balances: Balance[] = undefined;
  @JsonProperty('roles', [], true)
  roles: any[] = [];
  @JsonProperty('apply_bonuses', Boolean, true)
  applyBonuses: boolean = undefined;
  @JsonProperty('is_locked', Boolean, true)
  isLocked: boolean = undefined;
  @JsonProperty('percent', Number, true)
  percent: number = undefined;
  @JsonProperty('currency', String, true)
  currency: string = undefined;
  @JsonProperty('balance', Any, true)
  balance: any = undefined;
  @JsonProperty('affiliate_id', Number, true)
  affiliateId: number = undefined;
  @JsonProperty('phone_number', Any, true)
  phoneNumber: any = undefined;
  @JsonProperty('is_phone_confirmed', Boolean, true)
  isPhoneConfirmed: boolean = undefined;
  @JsonProperty('is_email_confirmed', Boolean, true)
  isEmailConfirmed: boolean = undefined;
  @JsonProperty('is_document_confirmed', Boolean, true)
  isDocumentConfirmed: boolean = undefined;
  @JsonProperty('permissions', [RolePermission], true)
  permissions: RolePermission[] = [];
  @JsonProperty('options', Any, true)
  options: any = undefined;
  @JsonProperty('level', Any, true)
  level: any = undefined;
  @JsonProperty('credit', Any, true)
  credit: any = undefined;
  @JsonProperty('cashier_credit', Any, true)
  cashierCredit: any = undefined;
  @JsonProperty('affiliate_credit', Any, true)
  affiliateCredit: any = undefined;
  @JsonProperty('is_credit_supported', Boolean, true)
  isCreditSupported: boolean = undefined;
  @JsonProperty('component_urls', Any, true)
  componentUrls: { admin: any, affiliate: any, cashier: any } = undefined;

  get fullName() {
    let name = '#' + this.id;
    if (this.firstName && this.lastName) {
      name = this.firstName + ' ' + this.lastName;
    } else {
      if (this.email) {
        name = this.email;
      } else {
        if (this.phoneNumber) {
          name = this.phoneNumber;
        }
      }
    }
    return name;
  }

  /* get real balance */
  getBalanceValue() {
    if (this.balances && this.balances.length > 0) {
      return this.balances[0].balance / 100;
    }
    return 0;
  }

  /* get formatted balance with two points after comma */
  getBalance() {
    if (this.balances && this.balances.length > 0) {
      return (this.balances[0].balance / 100).toFixed(2);
    }
    return '0.00';
  }

  setBalance(balance) {
    if (this.balances && this.balances.length > 0) {
      this.balances[0].balance = balance;
    }
  }

  getBalanceId() {
    if (this.balances && this.balances.length > 0) {
      return this.balances[0].id;
    }
    return null;
  }

  getCurrency() {
    if (this.balances && this.balances.length > 0) {
      return this.balances[0].currency;
    }
    return '';
  }

  getCashBack() {
    if (this.balances && this.balances.length > 0) {
      return (this.balances[0].cashBack / 100).toFixed(2);
    }
    return '0.00';
  }

  setCashBack(cashBack) {
    if (this.balances && this.balances.length > 0) {
      this.balances[0].cashBack = cashBack;
    }
  }

}
