import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/index';

@Injectable({
  providedIn: 'root'
})
export class SeonService {

  private $session = new BehaviorSubject(null);

  get session() {
    return this.$session;
  }

  get sessionValue() {
    return this.$session?.value;
  }

  set sessionValue(value) {
    this.$session.next(value);
  }

}
