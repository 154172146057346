import {environment} from '../../../environments/environment';

function availableLanguages() {
  const available = {};
  for(let lang in SiteConstant.ALL_LANGUAGES) {
    if (environment.language.excluded.indexOf(lang) === -1) {
      available[lang] = SiteConstant.ALL_LANGUAGES[lang];
    }
  }
  return available;
}

export default class SiteConstant {

  public static ALL_LANGUAGES = {
    uk: 'UA',
    en: 'EN',
    pl: 'PL',
    fr: 'FR',
    zh: 'ZH'
  }

  public static ALL_LANGUAGES_NAMES = {
    uk: 'Ukrainian',
    en: 'English',
    pl: 'Polish',
    fr: 'French',
    zh: 'Chinese'
  }

  public static LANGUAGES = availableLanguages();

  public static DEFAULT_LANGUAGE = environment.language.default || 'en';

  public static CURRENCY_CODES = {
    UAH: '&#8372;',
    USD: '&#36;',
    EUR: '&#128;',
    PLN: '&#122;&#322;'
  };

  public static USER_ROLES = {
    ROLE_USER: 10,
    ROLE_ADMIN: 20,
    ROLE_AFFILIATE:  30,
    ROLE_CASHIER:  40
  };

  public static BONUS_TYPES = {
    TYPE_CASH_BACK: 10,
    TYPE_DEPOSIT_INCREASE: 20,
    TYPE_WELCOME_BACK: 30
  };

  public static PHONE_PREFIX = '+38';

}
