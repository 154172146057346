import {Component, OnInit} from '@angular/core';
import {AuthService} from './casino/services/auth.service';
import {Router} from '@angular/router';
import {environment} from '../environments/environment';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import SiteConstant from './casino/helpers/constants';
import {UtmQueryParamsService} from './casino/services/utm.query.params.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'casino-frontend';

  templateConfig = environment;
  userSub: Subscription;
  user: any;

  constructor(public authService: AuthService,
              private router: Router,
              private utmQueryParamsService: UtmQueryParamsService,
              private translate: TranslateService) {

    this.authService.info(true).subscribe();

    this.userSub = this.authService.currentUser.subscribe(user => {
      this.user = user;
      if (this.user) {
        /* check currency in balance */
        if (this.user && (!this.user.balances || this.user.balances.length === 0)) {
          this.router.navigate(['check-currency']);
        }
      }
    });

  }

  ngOnInit() {
    this.initTranslation();
  }

  initTranslation() {

    const defaultLang = SiteConstant.DEFAULT_LANGUAGE;
    const browserLang = this.translate.getBrowserLang().toLowerCase();
    const storageLang = localStorage.getItem('lang')?.toLowerCase();
    let lang = defaultLang;

    if (SiteConstant.LANGUAGES[browserLang]) {
      lang = browserLang;
    }
    if (SiteConstant.LANGUAGES[storageLang]) {
      lang = storageLang;
    }

    this.translate.use(lang);
  }

}
