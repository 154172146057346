import {formatDate} from '@angular/common';
import {of} from 'rxjs';
import {MessageService} from 'primeng/api';

export default class Utils {

  static bannerTypes = [
    {name: 'Plain Text', id: 'plain'},
    {name: 'Jack Pot', id: 'jackpot'},
    {name: 'First Deposit', id: 'firstdeposit'},
    {name: 'Cashback', id: 'cashback'},
    {name: 'First Deposit Detailed', id: 'firstdepositdetailed'},
    {name: 'Fast Payment', id: 'fastpayment'},
    {name: 'More than 90 novelties', id: 'inbet'},
    {name: 'Online Cashier', id: 'onlinecashier'},
    {name: 'Registration', id: 'registration'}
  ];

  static setCookie(cname: any, cvalue: any, exdays: any): void {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  static getCookie(cname): any {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let c of ca) {
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  static geSiteLang(): any {
    const cookieLang = this.getCookie('lang');
    if (cookieLang) {
      return cookieLang;
    } else {
      return 'en';
    }
  }

  static toMoney(value): string {
    if (value) {
      return (value / 100).toFixed(2);
    }
    return '0.00';
  }

  static toPercent(value): string {
    if (value) {
      return value.toFixed(2) + ' %';
    }
    return '0.00 %';
  }

  static percentToDecimal(value): any {
    if (value) {
      const v = Math.floor(value * 100) / 100;
      return v / 100;
    }
    return 0;
  }

  static decimalToPercent(value): any {
    if (value) {
      return parseFloat(value) * 100;
    }
    return 0;
  }

  static toIntPercent(value): string {
    if (value) {
      return value * 100 + ' %';
    }
    return '0 %';
  }

  static toDate(value): string {
    if (value) {
      return formatDate(value, 'yyyy-MM-dd h:mm:ss', 'en-US');
    }
    return '';
  }

  static normalizeDate(date): any {
    if (date) {
      return formatDate(date, 'yyyy-MM-dd', 'en-US');
    }
    return null;
  }

  static normalizeDateTime(date): any {
    if (date) {
      return formatDate(date, 'yyyy-MM-ddTHH:mm:ss', 'en-US');
    }
    return null;
  }

  static isMobileDevice(): boolean {
    let hasTouchScreen = false;
    const maxTouchPointsKey = 'maxTouchPoints';
    const msMaxTouchPointsKey = 'msMaxTouchPoints';
    const userAgentKey = 'userAgent';
    if (maxTouchPointsKey in navigator) {
        hasTouchScreen = navigator[maxTouchPointsKey] > 0;
    } else if (msMaxTouchPointsKey in navigator) {
        hasTouchScreen = navigator[msMaxTouchPointsKey] > 0;
    } else {
        const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
        if (mQ && mQ.media === '(pointer:coarse)') {
            hasTouchScreen = !!mQ.matches;
        } else if ('orientation' in window) {
            hasTouchScreen = true;
        } else {
            const UA = navigator[userAgentKey];
            hasTouchScreen = (
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
            );
        }
    }
    return hasTouchScreen;
  }

  static setSessionStorage(key: any, value: any): void {
    try {
      if (sessionStorage) {
        sessionStorage.setItem(key, value);
      }
    } catch (e) {
      console.log(e);
    }
  }

  static removeSessionStorage(key: any): void {
    try {
      if (sessionStorage) {
        sessionStorage.removeItem(key);
      }
    } catch (e) {
      console.log(e);
    }
  }

  static getSessionStorage(key): any {
    try {
      if (sessionStorage) {
        const data = sessionStorage.getItem(key);
        if (data) {
          return data;
        }
      }
    } catch (e) {
      console.log(e);
      return null;
    }
    return null;
  }

  static handleHttpError(error, title, value) {
    if (title) {
      console.error(title);
    } else {
      console.error('Something went wrong, please try again later');
    }
    console.error(error);
    if (value) {
      return of(value);
    }
    return of(null);
  }

  static sortObjectArray(arr: Array<any>, param: string, direction: number = 1): Array<any> {
    if (direction == 1) {
      arr.sort((a, b) => (a[param] < b[param] ? -1 : 1));
    } else if (direction == -1) {
      arr.sort((a, b) => (a[param] < b[param] ? 1 : -1));
    } else {
      return []
    }
    return arr;
  }

  static getErrorMessage (error: any): any {
    if (error.status === 'fail') {
      return {severity: 'error', summary: 'Error', detail: JSON.stringify(error.data)};
    } else {
      return {severity: 'error', summary: 'Error', detail: JSON.stringify(error)};
    }
  }

  static showMsg(data: any, servise: MessageService): void {
    if (data.param == 'err') {
      servise.add(Utils.getErrorMessage(data.msg));
    } else {
      servise.add(data.msg);
    }
  }

  static arrayToMap(items: Array<any>): Map<any, any> {
    const mapObj = new Map();
    for (const item of items) {
      mapObj.set(item.id, item);
    }
    return mapObj;
  }

  static getBannerTypeById(bannerTypeId, bannerTypes): any {
    for (const b of bannerTypes) {
      if (b.id === bannerTypeId) {
        return b;
      }
    }
    return null;
  }

  static getItemById(id, items): any {
    for (const b of items) {
      if (b.id === id) {
        return b;
      }
    }
    return null;
  }

  static setLocalStorageItem(key: any, value: any): void {
    try {
      if (localStorage) {
        localStorage.setItem(key, value);
      }
    } catch (e) {
      console.log(e);
    }
  }

  static removeLocalStorageItem(key: any): void {
    try {
      if (localStorage) {
        localStorage.removeItem(key);
      }
    } catch (e) {
      console.log(e);
    }
  }

  static getLocalStorageItem(key): any {
    try {
      if (localStorage) {
        const data = localStorage.getItem(key);
        if (data) {
          return data;
        }
      }
    } catch (e) {
      console.log(e);
      return null;
    }
    return null;
  }

}
